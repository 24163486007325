import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "pdf-tokens"
    }}>{`PDF Tokens`}</h1>
    <p>{`PDF Tokens are embedded in Adobe Acrobat files, referred to by most as PDF files.  PDF files are the most common way for organizations to publish information that is intended to be portable.  For example, invoices, company policies, tax documents, bank documents, corporate policies, whitepapers, and more.  Once configured, PDFs are a reliable way to trigger Dragnet Security Tokens. Additionally, there are a variety of `}<strong parentName="p">{`free`}</strong>{` PDF reader applications that work on a variety of platforms including Windows, Mac OS, and Linux.`}</p>
    <p>{`Our PDF tokens work by imbedding javascript in the PDF document so that when it is opened, the document calls a remote sever, triggering the alarm. `}</p>
    <h3 {...{
      "id": "verify-the-default-pdf-reader-triggers-an-alert"
    }}>{`Verify the Default PDF Reader Triggers an Alert`}</h3>
    <p>{`Any PDF viewer application that supports JavaScript function should work. However, it is critical that you verify that the default PDF reader application on the machine upon which the token is placed is able to trigger the alarm.  For example, Adobe Acrobat and Adobe Reader both support the function. However, most web-based PDF previewers will not work. Additionally, the native MacOS PDF viewer will not trigger a token.`}</p>
    <p>{`Additionally, some PDF readers will prompt you before allowing the document to make the remote call to trigger the alarm. `}</p>
    <h4 {...{
      "id": "example-1"
    }}>{`Example 1`}</h4>
    <p><img alt="pdf image" src={require("./public/PDF-1.png")} /></p>
    <h4 {...{
      "id": "example-2"
    }}>{`Example 2`}</h4>
    <p><img alt="pdf image" src={require("./public/PDF-2.png")} /></p>
    <p>{`If you see this, you will need to allow the function and accept any user prompts which will allow these functions in the future.  Your goal should be to ensure the environment in which the token is placed allows it to trigger the alarm without requiring more than one action on the part of a threat actor.`}</p>
    <h3 {...{
      "id": "limitations"
    }}>{`Limitations`}</h3>
    <p>{`When the JavaScript executes, it prompts the computer to open a web browser, which might alert an attacker that you are aware of their presence. If you would like to avoid this, we recommend MS Word tokens or Windows Folder tokens, assuming the tokens are being placed into an environment where those documents will work.`}</p>
    <h3 {...{
      "id": "templates"
    }}>{`Templates`}</h3>
    <p>{`The only available template for a PDF document is simply a blank document.  However, if you want to create a document that contains your own template, below are the procedures. Note that you'll need a PDF creation or editing tool that provides access to Adobe PDF JavaScript functions.  The instructions below are for Adobe Acrobat.`}</p>
    <ol>
      <li parentName="ol">{`Create a PDF Token following the instructions in the Quick Start Guide.`}</li>
      <li parentName="ol">{`Navigate to view your token details.`}</li>
      <li parentName="ol">{`Copy the `}<inlineCode parentName="li">{`Token Link`}</inlineCode>{` to your clipboard.`}</li>
      <li parentName="ol">{`Open your own PDF with your desired content in Adobe Acrobat.`}</li>
      <li parentName="ol">{`Navigate to `}<inlineCode parentName="li">{`Tools`}</inlineCode>{` > `}<inlineCode parentName="li">{`Organize Pages`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Select the first page of the document and click the `}<inlineCode parentName="li">{`More`}</inlineCode>{` button.`}</li>
      <li parentName="ol">{`Select `}<inlineCode parentName="li">{`Page Properties`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Select `}<inlineCode parentName="li">{`Page Open`}</inlineCode>{` from the `}<inlineCode parentName="li">{`Select Trigger`}</inlineCode>{` drop down menu.`}</li>
      <li parentName="ol">{`Select `}<inlineCode parentName="li">{`Open a web link`}</inlineCode>{` from the `}<inlineCode parentName="li">{`Select Action`}</inlineCode>{` drop down menu.`}</li>
      <li parentName="ol">{`Click the `}<inlineCode parentName="li">{`Add`}</inlineCode>{` button and enter the `}<inlineCode parentName="li">{`Token Link`}</inlineCode>{` from Step 3 into the input.`}</li>
      <li parentName="ol">{`Save, close, and reopen the document to test that it functions.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      